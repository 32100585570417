import statusCSS from "../utils/ApplicationStatus";
import classes from "../assets/css/Columns.module.css";
import { IconButton, Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ColumnLinks from "../components/Table/ColumnLinks";
import { ArrowOutward, Delete } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Status from "../components/certificates/Status";

const applications = [
  { field: "number", headerName: "Application No.", width: 150 },
  {
    field: "referral_number",
    headerName: "Referral No.",
    width: 150,
    renderCell: ({ row }) => {
      if (row.referral_number === null || row.referral_number === "") {
        return "N/A";
      }

      return (
        <Typography
          sx={{
            color: "#0086C9",
            textDecoration: "underline",
            display: "flex",
            flexDirection: "row",
            typography: "textSmSemi",
            alignItems: "center",
          }}
        >
          {row.referral_number} <ArrowOutwardIcon sx={{ width: 12, ml: 0.3 }} />
        </Typography>
      );
    },
    // params.row.referral_number === null || params.row.referral_number === ""
    //   ? "N/A"
    //   : params.row.referral_number,
  },
  {
    field: "full_name",
    headerName: "Full Name",
    width: 150,

    renderCell: (params) => (
      <Typography
        sx={{
          color: "#0086C9",
          textDecoration: "underline",
          display: "flex",
          flexDirection: "row",
          typography: "textSmSemi",
          alignItems: "center",
          whiteSpace: "pre-wrap",
        }}
      >
        {params.row.full_name} <ArrowOutwardIcon sx={{ width: 12, ml: 0.3 }} />
      </Typography>
    ),
  },
  {
    field: "opening",
    headerName: "Opening",
    width: 200,
    renderCell: (params) => (
      <Typography
        component="a"
        href={`https://careers.idsil.com/applications/${
          params.row.opening?.id
        }?opening=${params.row.opening?.title
          .toLowerCase()
          .replace(/\s/g, "-")}&job=${params.row.job?.title
          .replace(/\s/g, "-")
          .replace(/,+/g, "")
          .replace(/-+/g, "-")
          .toLowerCase()}`}
        target="__blank"
        rel="noreferrer noopener"
        sx={{
          color: "#0086C9",
          textDecoration: "underline",
          display: "flex",
          flexDirection: "row",
          typography: "textSmSemi",
          alignItems: "center",
          whiteSpace: "pre-wrap",
        }}
      >
        {params.row.opening?.title}{" "}
        <ArrowOutwardIcon sx={{ width: 12, ml: 0.3 }} />
      </Typography>
    ),
  },
  { field: "email", headerName: "Email", width: 180 },
  { field: "phone", headerName: "Mobile No.", width: 135 },
  {
    field: "created_at",
    headerName: "Date",
    width: 150,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
  {
    field: "stage",
    headerName: "Status",
    width: 130,
    renderCell: (params) => (
      <Typography
        sx={{
          ...statusCSS(params.row.stage?.name),
          borderRadius: 4,
          px: 0.8,
          py: 0.3,
          display: "flex",
          flexDirection: "row",
          width: "max-content",
          typography: "textXsReg",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {params.row.stage?.name}{" "}
        <ArrowForwardIcon sx={{ width: 12, ml: 0.3 }} />
      </Typography>
    ),
  },
  { field: "experience", headerName: "Experience", width: 130 },
  {
    field: "job",
    headerName: "Applied For",
    width: 130,
    renderCell: (params) => {
      return params.row.job?.title;
    },
  },

  { field: "message", headerName: "Message", width: 130 },
  { field: "qualifications", headerName: "Qualifications", width: 130 },
  {
    field: "country",
    headerName: "Country",
    width: 130,
    renderCell: (params) => {
      return params.row.country?.name;
    },
  },

  { field: "current_location", headerName: "Current Location", width: 130 },
  {
    field: "preferred_location",
    headerName: "Preferred Location",
    width: 130,
  },
  { field: "passing_year", headerName: "Passing Year", width: 130 },
  { field: "has_history", headerName: "History with IDS", width: 130 },
  {
    field: "is_recent_interview",
    headerName: "Recently Interviewed",
    width: 130,
  },
  {
    field: "documents",
    headerName: "Documents",
    width: 180,
    valueFormatter: ({ value }) => {
      return value
        ?.split(",")
        ?.map((docs, i) => {
          return `${process.env.REACT_APP_IDS_BACKEND_URL}applications/documents/${docs}`;
        })
        .join("\r\n");
    },

    renderCell: (params) => {
      const documents = params.row.documents?.split(",");
      return documents?.map((docs, i) => (
        <ColumnLinks
          index={i}
          value={docs}
          valueLength={documents.length}
          name="documents"
        />
      ));
    },
  },
  {
    field: "certificates",
    headerName: "certificates",
    width: 180,
    cellClassName: classes.multilineCell,
    valueFormatter: ({ value }) => {
      return value
        ?.split(",")
        ?.map((docs, i) => {
          return `${process.env.REACT_APP_IDS_BACKEND_URL}applications/certificates/${docs}`;
        })
        .join("\r\n");
    },
    renderCell: (params) => {
      const certificates = params.row.certificates?.split(",");
      return certificates?.map((docs, i) => (
        <ColumnLinks
          index={i}
          value={docs}
          valueLength={certificates.length}
          name="certificates"
        />
      ));
    },
  },
];

const contacts = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "full_name",
    headerName: "Full Name",
    width: 200,
    renderCell: (params) => (
      <Typography
        sx={{
          color: "#0086C9",
          textDecoration: "underline",
          display: "flex",
          flexDirection: "row",
          typography: "textSmSemi",
          alignItems: "center",
        }}
      >
        {params.row.full_name} <ArrowOutwardIcon sx={{ width: 12, ml: 0.3 }} />
      </Typography>
    ),
  },
  { field: "email", headerName: "Email", width: 200 },
  { field: "phone", headerName: "Phone No.", width: 180 },
  { field: "country", headerName: "Country", width: 130 },
  { field: "message", headerName: "Message", width: 130 },
  {
    field: "inquiry",
    headerName: "Inquiry Type",
    width: 200,
    renderCell: (params) => params.row.inquiry?.name,
    valueFormatter: ({ value }) => value?.name,
  },
  {
    field: "company",
    headerName: "Company",
    width: 200,
    renderCell: (params) =>
      params.row.company === null || params.row.company === ""
        ? "N/A"
        : params.row.company,
  },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
];

const webinars = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "full_name",
    headerName: "Full Name",
    width: 130,
    valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`,
  },
  { field: "email", headerName: "Email", width: 130 },
  { field: "phone", headerName: "Phone No.", width: 130 },
  { field: "message", headerName: "Message", width: 130 },
  { field: "type", headerName: "Type", width: 130 },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
];

const blood_donations = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "full_name",
    headerName: "Full Name",
    width: 130,
    valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`,
  },
  { field: "email", headerName: "Email", width: 130 },
  { field: "phone", headerName: "Phone No.", width: 130 },
  { field: "company", headerName: "Company", width: 130 },
  { field: "location", headerName: "Location", width: 130 },
  { field: "employee_code", headerName: "Employee Code", width: 130 },
  { field: "message", headerName: "Message", width: 130 },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
];

const volunteers = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "full_name",
    headerName: "Full Name",
    width: 130,
    valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`,
  },
  { field: "email", headerName: "Email", width: 130 },
  { field: "phone", headerName: "Phone No.", width: 130 },
  { field: "company", headerName: "Company", width: 130 },
  { field: "employee_code", headerName: "Employee Code", width: 130 },
  { field: "questions", headerName: "Questions", width: 130 },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
];

const subscribers = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "email", headerName: "Email", width: 160 },
  { field: "type", headerName: "Type", width: 130 },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
];

const jobList = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "title", headerName: "Title", width: 130 },
  { field: "type", headerName: "Type", width: 130 },
  { field: "work_mode", headerName: "Work Mode", width: 130 },
  { field: "experience", headerName: "Experience", width: 130 },
  { field: "description", headerName: "Description", width: 130 },
  { field: "is_review", headerName: "Review Status", width: 130 },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
  {
    field: "delete",
    headerName: "Delete",
    width: 100,
    renderCell: (params) => {
      return (
        <Stack
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
          }}
        >
          <IconButton>
            <Delete />
          </IconButton>
        </Stack>
      );
    },
  },
  {
    field: "edit",
    headerName: "Edit entry",
    width: 130,
    renderCell: (params) => {
      return (
        <Link
          to={`${params.row.id}`}
          style={{
            fontWeight: "bold",
            ":hover": {
              color: "primary.primary700",
              bgcolor: "grey.grey200",
            },
            textDecoration: "none",
          }}
        >
          Edit <ArrowOutward sx={{ width: 12, ml: 0.3 }} />
        </Link>
      );
    },
  },
];

const caseStudy = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "title", headerName: "Title", width: 100 },
  { field: "subtitle", headerName: "Sub Title", width: 130 },
  { field: "about", headerName: "Type", width: 130 },
  // { field: "description", headerName: "Work Mode", width: 130 },
  // { field: "experience", headerName: "Experience", width: 130 },
  // { field: "description", headerName: "Description", width: 130 },
  // { field: "is_review", headerName: "Review Status", width: 130 },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
  {
    field: "delete",
    headerName: "Delete",
    width: 100,
    renderCell: (params) => {
      return (
        <Stack
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
          }}
        >
          <IconButton>
            <Delete />
          </IconButton>
        </Stack>
      );
    },
  },
  {
    field: "edit",
    headerName: "Edit entry",
    width: 130,
    renderCell: (params) => {
      return (
        <Link
          to={`/ehs/case-studies/${params.row.id}/edit`}
          style={{
            fontWeight: "bold",
            ":hover": {
              color: "primary.primary700",
              bgcolor: "grey.grey200",
            },
            textDecoration: "none",
          }}
        >
          Edit <ArrowOutward sx={{ width: 12, ml: 0.3 }} />
        </Link>
      );
    },
  },
];

const ssbCaseStudy = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "title", headerName: "Title", width: 100 },
  { field: "subtitle", headerName: "Sub Title", width: 130 },
  { field: "description", headerName: "Description", width: 130 },
  // { field: "description", headerName: "Work Mode", width: 130 },
  // { field: "experience", headerName: "Experience", width: 130 },
  // { field: "description", headerName: "Description", width: 130 },
  // { field: "is_review", headerName: "Review Status", width: 130 },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
  {
    field: "delete",
    headerName: "Delete",
    width: 100,
    renderCell: (params) => {
      return (
        <Stack
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "flex-end",
          }}
        >
          <IconButton>
            <Delete />
          </IconButton>
        </Stack>
      );
    },
  },
  {
    field: "edit",
    headerName: "Edit entry",
    width: 130,
    renderCell: (params) => {
      return (
        <Link
          to={`/ssb/case-studies/${params.row.id}/edit`}
          style={{
            fontWeight: "bold",
            ":hover": {
              color: "primary.primary700",
              bgcolor: "grey.grey200",
            },
            textDecoration: "none",
          }}
        >
          Edit <ArrowOutward sx={{ width: 12, ml: 0.3 }} />
        </Link>
      );
    },
  },
];

const certificateTemplates = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "name", headerName: "Name", width: 100 },
  { field: "alias", headerName: "Alias", width: 130 },
  {
    field: "image",
    headerName: "Certificate",
    width: 130,
    renderCell: (params) => {
      return (
        <a
          href={`${process.env.REACT_APP_IDS_BACKEND_URL}certificates/${params.row.image}`}
          target="_blank"
          rel="noreferrer noopener"
          style={{
            // fontWeight: "bold",
            ":hover": {
              color: "primary.primary700",
              // bgcolor: "grey.grey200",
            },
            textDecoration: "none",
          }}
        >
          <InsertDriveFileIcon sx={{ width: 20, height: 20, ml: 6 }} />
        </a>
      );
    },
  },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
  // {
  //   field: "delete",
  //   headerName: "Delete",
  //   width: 100,
  //   renderCell: (params) => {
  //     return (
  //       <Stack
  //         sx={{
  //           display: "flex",
  //           justifyContent: "end",
  //           alignItems: "flex-end",
  //         }}
  //       >
  //         <IconButton onCl>
  //           <Delete />
  //         </IconButton>
  //       </Stack>
  //     );
  //   },
  // },
  {
    field: "edit",
    headerName: "Edit entry",
    width: 130,
    renderCell: (params) => {
      return (
        <Link
          to={`/certificates/${params.row.id}/edit-template`}
          style={{
            fontWeight: "bold",
            ":hover": {
              color: "primary.primary700",
              bgcolor: "grey.grey200",
            },
            textDecoration: "none",
          }}
        >
          Edit <ArrowOutward sx={{ width: 12, ml: 0.3 }} />
        </Link>
      );
    },
  },
];

const certificates = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "employee_name", headerName: "Employee Name", width: 100 },
  { field: "employee_code", headerName: "Employee Code", width: 130 },
  {
    field: "year",
    headerName: "Year",
    width: 130,
  },
  { field: "reason", headerName: "Reason", width: 130 },
  {
    field: "certificate",
    headerName: "Certificate Name",
    width: 150,
    valueGetter: (params) => params.row.certificate_template.name,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
  },
  {
    field: "action",
    headerName: "Action",
    width: 200,
    renderCell: (params) =>
      params.row.status === "pending" ? (
        <Status id={params.row.id} status={params.row.status} />
      ) : (
        params.row.status
      ),
  },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
  // {
  //   field: "delete",
  //   headerName: "Delete",
  //   width: 100,
  //   renderCell: (params) => {
  //     return (
  //       <Stack
  //         sx={{
  //           display: "flex",
  //           justifyContent: "end",
  //           alignItems: "flex-end",
  //         }}
  //       >
  //         <IconButton>
  //           <Delete />
  //         </IconButton>
  //       </Stack>
  //     );
  //   },
  // },
  {
    field: "edit",
    headerName: "Edit entry",
    width: 130,
    renderCell: (params) => {
      return (
        <Link
          to={`/certificates/${params.row.id}/edit`}
          style={{
            fontWeight: "bold",
            ":hover": {
              color: "primary.primary700",
              bgcolor: "grey.grey200",
            },
            textDecoration: "none",
          }}
        >
          Edit <ArrowOutward sx={{ width: 12, ml: 0.3 }} />
        </Link>
      );
    },
  },
];

const certificateToEmployees = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "employee_name", headerName: "Employee Name", width: 100 },
  { field: "employee_code", headerName: "Employee Code", width: 130 },
  {
    field: "year",
    headerName: "Year",
    width: 130,
  },
  { field: "reason", headerName: "Reason", width: 130 },
  {
    field: "certificate_template_name",
    headerName: "Certificate Name",
    width: 150,
    // valueGetter: (params) => params.row.certificate_template.name,
  },
  // {
  //   field: "delete",
  //   headerName: "Delete",
  //   width: 100,
  //   renderCell: (params) => {
  //     return (
  //       <Stack
  //         sx={{
  //           display: "flex",
  //           justifyContent: "end",
  //           alignItems: "flex-end",
  //         }}
  //       >
  //         <Trigger
  //           {...params}
  //           buttonType="button"
  //           id={params.row.id}
  //           message={"Are you sure you want to delete this item?"}
  //         />
  //       </Stack>
  //     );
  //   },
  // },
];

const healthcareLeads = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "FirstName", headerName: "First Name", width: 100 },
  { field: "LastName", headerName: "Last Name", width: 130 },
  { field: "Email", headerName: "Email", width: 130 },
  {
    field: "Mobile",
    headerName: "Mobile",
    width: 140,
    // renderCell: (params) => {
    //   return <Stack> { params.row?.Mobile} </Stack>;
    // },
  },
  {
    field: "Specialty",
    headerName: "Specialty",
    width: 130,
  },
  {
    field: "Role",
    headerName: "Delete",
    width: 100,
  },
  {
    field: "Description",
    headerName: "Description",
    width: 100,
  },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
];
const healthcareGuides = [
  { field: "id", headerName: "ID", width: 100 },
  { field: "name", headerName: "Name", width: 100 },

  { field: "email", headerName: "Email", width: 130 },
  {
    field: "phone",
    headerName: "Mobile",
    width: 100,
  },
  {
    field: "interest",
    headerName: "Interest",
    width: 130,
  },
  {
    field: "created_at",
    headerName: "Filled On",
    width: 130,
    valueGetter: (params) => new Date(params.row.created_at).toLocaleString(),
  },
];
const Columns = {
  applications,
  contacts,
  webinars,
  blood_donations,
  subscribers,
  jobList,
  caseStudy,
  ssbCaseStudy,
  volunteers,
  certificateTemplates,
  certificates,
  certificateToEmployees,
  healthcareLeads,
  healthcareGuides,
};
export default Columns;
